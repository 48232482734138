/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useContext } from 'react';
import { Form, Input, Button, Row } from 'antd';
import axios from 'axios';
import Logo from './img/logo_black.png';
import ThemeContext from './context';
// import { render } from '@testing-library/react';

function LoginForm(context) {

  const [credsError, setCredsError] = useState(null);
  const { setVesselName } = useContext(ThemeContext) 

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const usernameRequiredRules = [
    {
      required: true,
      message: 'Введите имя!',
    },
  ];

  const passwordRequiredRules = [
    {
      required: true,
      message: 'Введите свой пароль!',
    },
  ];

  const onSubmitButtonClick = async (auth) => {
    try {
      let profile = await axios.get(`https://lk.transcargo-group.com/basezip/hs/profile/${auth.username}`, { auth });
      // console.log({ profile });
      let vesselCode = profile.data[0] && profile.data[0].Vessel;
      context.setVesselCode(vesselCode);
      console.log(profile.data[0].VesselName);
      setVesselName(profile.data[0].VesselName)

      let orders = await axios.get(`https://lk.transcargo-group.com/basezip/hs/zayavki/${vesselCode}`, { auth });
      context.setOrders(orders.data);

      let vendors = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/manufacturer`, { auth });
      context.setVendors(vendors.data);

      let components = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/component`, { auth });
      context.setComponents(components.data);

      let catvessel = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/catvessel`, { auth });
      context.setСatvessel(catvessel.data);  //категория судна
      //------------------категории заявок для судов---------------------------------------------------------
      let spareParts = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/spareParts`, { auth });
      // console.log('spareParts', { spareParts });
      context.setSpareParts(spareParts.data);

      let expendableSupply = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/expendableSupply`, { auth });
      // console.log('catexpendableSupply', { expendableSupply });
      context.setExpendableSupply(expendableSupply.data);

      let services = await axios.get(`https://lk.transcargo-group.com/basezip/hs/guide/services`, { auth });
      // console.log('services', { services });
      context.setServices(services.data);

    } catch (e) {
      console.error(e);
      setCredsError('Неправильный логин или пароль');
      return;
    }
    context.setAuth(auth);
    setCredsError(null);
  };

  return (
    <div >
      <img className='pic' src={Logo} alt='logo' />

      <Row type="flex" justify="center" align="middle" style={{ minHeight: '50vh' }}>

        <Form
          {...layout}
          name="basic"
          onFinish={onSubmitButtonClick}
        >
          <Form.Item
            label="Имя"
            name="username"
            rules={usernameRequiredRules}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={passwordRequiredRules}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            {credsError && credsError.length > 0 && (<p>{credsError}</p>)}
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
}

export default LoginForm;
